
export const useSnackbarStore = defineStore('snackbar', () => {
  const visible = ref(false);
  const color = ref('');
  const message = ref('');

  function showSnackbar(options) {
    color.value = options.color;
    message.value = options.message;
    visible.value = true;
  }

  function showError(message) {
    showSnackbar({ color: 'error', message });
  }

  function closeSnackbar() {
    visible.value = false;
  }

  return {
    visible,
    color,
    message,
    showSnackbar,
    showError,
    closeSnackbar,
  };
});
