// composables/useEmailSender.ts
import { ref, onMounted } from 'vue';

export function useEmailSender() {

  // 生成 mailto 链接
  const generateMailToLink = (subject: string = '', body: string = '') => {
    const encodedBody = encodeURIComponent(body);
    return `mailto:feedback@bestfaceswap.ai?subject=${encodeURIComponent(subject)}&body=${encodedBody}`;
  };

  // 发送邮件函数，接受 subject 和 body 参数
  const sendEmail = (subject: string = '', body: string = '') => {
    window.location.href = generateMailToLink(subject, body);
  };

  return {
    sendEmail,
    generateMailToLink,
  };
}