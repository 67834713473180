import { defineStore } from 'pinia';
import type { UserInfo, Product, AppInfo } from '~/types';
import api from '@/api';

export const useCommStore = defineStore('comm', () => {
    const userInfo = ref<UserInfo | null>(null);
    const products = ref<Product[]>([]);
    const appInfo = ref<AppInfo>({ showSingleFacePopup: true }); // 设置默认值

    const setUserInfo = async (info?: UserInfo) => {
        if (info) {
            userInfo.value = info;
        } else {
            try {
                const userInfoResp = await api.userinfo();
                if (userInfoResp) {
                    userInfo.value = userInfoResp;
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const clearUserInfo = () => {
        userInfo.value = null;
    };

    const setProducts = (prod: Product[]) => {
        products.value = prod;
    };

    const setAppInfo = (info: Partial<AppInfo>) => {
        appInfo.value = { ...appInfo.value, ...info };
    };

    return {
        userInfo,
        products,
        appInfo,
        setUserInfo,
        clearUserInfo,
        setProducts,
        setAppInfo,
    };
}, {
    persist: true
});
