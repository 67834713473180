<template>
    <footer class="w-full bg-neutral-900 z-20">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <!--Grid-->
            <div
                class="grid grid-cols-2 lg:grid-cols-4 lg:gap-8 gap-12 pt-14 pb-16 max-w-md mx-auto md:max-w-xl lg:max-w-full">
                <div class="block">
                    <h3 class="text-xl text-white font-bold mb-7">BestFaceSwap.ai</h3>
                    <ul class="text-md  transition-all duration-500">
                        <li class="mb-6">
                            <NuxtLink to="/" class="text-white/50 hover:text-white">Home</NuxtLink>
                        </li>
                        <li class="mb-6">
                            <NuxtLink to="/pricing" class="text-white/50 hover:text-white duration-300">Pricing
                            </NuxtLink>
                        </li>
                    </ul>
                </div>
                <!--End Col-->
                <div class="block">
                    <h3 class="text-xl text-white font-bold mb-7">Products</h3>
                    <ul class="text-md  transition-all duration-500">
                        <li class="mb-6">
                            <NuxtLink to="/face-swap-video" class="text-white/50 hover:text-white">
                                Video Face Swap
                            </NuxtLink>
                        </li>
                        <li class="mb-6">
                            <NuxtLink to="/face-swap-photo" class="text-white/50 hover:text-white duration-300">
                                Photo Face Swap</NuxtLink>
                        </li>
                    </ul>
                </div>
                <!--End Col-->
                <div class="block">
                    <h3 class="text-xl text-white font-bold mb-7">Resources</h3>
                    <ul class="text-md  transition-all duration-500">
                        <li class="mb-6">
                            <NuxtLink to="/terms" class="text-white/50 hover:text-white duration-300">Terms &
                                Conditions</NuxtLink>
                        </li>
                        <li class="mb-6">
                            <NuxtLink to="/policy" class="text-white/50 hover:text-white duration-300">Privacy Policy
                            </NuxtLink>
                        </li>
                        <li class="mb-6"><a href="https://forms.gle/crWH7vpfPhidu6wWA" target="_blank"
                                class="text-white/50 hover:text-white duration-300">Affiliate Program</a>
                        </li>
                    </ul>
                </div>
                <!--End Col-->
                <div class="block">
                    <h3 class="text-xl text-white font-bold mb-7">Contact Us</h3>
                    <ul class="text-md  transition-all duration-500">

                        <li class="mb-6">
                            <a href="mailto:feedback@bestfaceswap.ai"
                                class="text-white/50 hover:text-white duration-300" @click.prevent="onSendEmail">
                                Email
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <!--Grid-->
            <div class="pb-24 lg:pb-6 pt-6 border-t border-gray-900">
                <div
                    class="flex items-center justify-center flex-col lg:space-y-0 space-y-8 lg:justify-between lg:flex-row">
                    <a href="https://www.bestfaceswap.ai" class="flex items-center">
                        <img class="h-8 w-8" src="/idx/bestfaceswap-logo.svg" alt="BestFaceSwap logo">
                        <div class="ml-4 font-semibold text-2xl text-white hidden lg:block">BestFaceSwap.ai</div>
                    </a>
                    <span class="text-white/50 block text-sm font-normal">©
                        <a href="https://bestfaceswap.ai/">BestFaceSwap.ai</a> 2024, All rights reserved.</span>
                    <div class="flex mt-4 space-x-4 sm:justify-center sm:mt-0">
                        <a href="mailto:feedback@bestfaceswap.ai"
                            class="w-12 h-12 rounded-full flex justify-center items-center bg-neutral-800 hover:bg-neutral-700 active:bg-neutral-600 duration-300"
                            @click.prevent="onSendEmail()">
                            <img class=" h-6 w-6" src="/idx/ic_social_mail.svg" alt="Mail">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script setup lang="ts">
const { sendEmail } = useEmailSender();
const comm = useCommStore();

const browserInfo = computed(() => navigator?.userAgent);
const generateAdditionalInfo = () => {
    let info = '';

    // 添加用户 ID 信息
    if (comm.userInfo?.uid) {
        info += `My Account ID: ${comm.userInfo.uid}\n`;
    }

    // 添加浏览器信息
    info += `My Browser: ${browserInfo.value}\n`;

    return info;
};

// 构建完整的邮件内容
const emailContent = computed(() => `Dear Team:

[Please describe your issue or suggestion here]


----------- Other Info -----------
${generateAdditionalInfo()}
`);

// 发送邮件
const onSendEmail = () => {
    sendEmail('', emailContent.value);
};
</script>