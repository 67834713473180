// middleware/utm.global.ts
export default defineNuxtRouteMiddleware((to) => {
  const utmStore = useUtmStore()

  const queryParams = to.query

  // 将 LocationQuery 转换为 Record<string, string>
  const parsedParams: Record<string, string> = Object.fromEntries(
    Object.entries(queryParams).map(([key, value]) => [key, Array.isArray(value) ? value[0] : value])
  )

  // 检查是否有 UTM 参数
  const hasUtmParams = parsedParams.utm_source || parsedParams.utm_medium || parsedParams.utm_campaign || parsedParams.utm_content || parsedParams.click_id || parsedParams.via

  // 仅在存在 UTM 参数时进行设置
  if (hasUtmParams) {
    utmStore.setUtmParams(parsedParams)
  }
})
