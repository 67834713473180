<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
const route = useRoute();
const isVisible = ref(true);
const showBannerPageList = ['/', '/face-swap-photo', '/face-swap-video'];
const shouldShowBanner = computed(() => {
    return showBannerPageList.includes(route.path);
});

const timer = ref<number>(0);

const formattedTime = computed(() => {
    const hours = Math.floor(timer.value / 3600);
    const minutes = Math.floor((timer.value % 3600) / 60);
    const secondsRemaining = timer.value % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secondsRemaining.toString().padStart(2, '0')}`;
});

const updateTimer = () => {
    if (timer.value > 0) {
        timer.value--;
        try {
            localStorage.setItem('countdown', timer.value.toString());
        } catch (error) {
            console.error('Failed to access localStorage:', error);
        }
    }
};

let intervalId: number;

onMounted(() => {
    intervalId = window.setInterval(updateTimer, 1000);
    try {
        const storedTime = localStorage.getItem('countdown');
        timer.value = storedTime ? parseInt(storedTime, 10) : 3600;
    } catch (error) {
        console.error('Failed to access localStorage:', error);
        timer.value = 3600; // fallback to default value if localStorage access fails
    }
});

onBeforeUnmount(() => {
    window.clearInterval(intervalId);
});

const hideBanner = () => {
    isVisible.value = false;
    const bannerElement = document.getElementById('banner-top');
    if (bannerElement) {
        bannerElement.classList.remove('pt-24', 'pt-40');
        bannerElement.classList.add(route.path === '/' ? '!pt-12' : '!pt-20');
    }
};
</script>

<template>
    <div>
        <div v-if="shouldShowBanner && isVisible"
            class="relative isolate flex items-center gap-x-6 overflow-hidden bg-orange-600 px-3 py-2 sm:px-3 sm:before:flex-1">
            <div class="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                aria-hidden="true">
                <div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#F5350B] to-[#FDE68A] opacity-70"
                    style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)">
                </div>
            </div>
            <div class="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                aria-hidden="true">
                <div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#F5350B] to-[#FDE68A] opacity-70"
                    style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)">
                </div>
            </div>
            <div class="w-full flex items-center justify-between gap-4">
                <NuxtLink to="/pricing" class="flex-1 flex justify-center gap-x-6 sm:gap-x-12 gap-y-2">
                    <div class="flex flex-wrap gap-x-5 sm:gap-x-12 items-center text-neutral-950">
                        <strong class="text-2xl font-semibold hidden sm:flex">First Subscription</strong>
                        <button
                            class="group gap-2 inline-flex items-center justify-center rounded-full py-3 pl-6 pr-4 text-base font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-neutral-950 text-white hover:opacity-90 active:opacity-80 duration-300"
                            style="animation: pulse 1s infinite;">
                            <span>UP To 50% Off</span>
                            <div class="relative right-0 group-hover:translate-x-1 w-5 duration-200">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round"
                                    stroke-linejoin="round" class="arrow-right">
                                    <path class="opacity-0 group-hover:opacity-100 duration-200" d="M5 12h14"></path>
                                    <path d="m12 5 7 7-7 7"></path>
                                </svg>
                            </div>
                        </button>
                        <p id="countdown"
                            class="text-lg font-bold text-white bg-orange-700 px-3 py-2 rounded-lg text-center">
                            {{ formattedTime }}
                        </p>
                    </div>
                </NuxtLink>
                <div class="flex-shrink-0">
                    <button type="button" class="p-3 focus-visible:outline-offset-[-4px]" @click="hideBanner">
                        <span class="sr-only">Dismiss</span>
                        <svg class="h-5 w-5 text-black/50" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path
                                d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.not-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>