// plugins/sentry.client.ts
import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  if (process.client && (config.public.web === 'test' || config.public.web === 'prod') ) {
    
    const { vueApp } = nuxtApp;

     // 根据环境动态设置 tracePropagationTargets
     const tracePropagationTargets =
     config.public.web === 'prod'
       ? [
          "localhost", // for local development
           /^https:\/\/(www\.)?bestfaceswap\.ai\/?/,
           /^https:\/\/api\.bestfaceswap\.ai\/?/,
         ]
       : [
          "localhost", // for local development
           /^https:\/\/test\.bestfaceswap\.ai\/?/,
           /^https:\/\/apitest\.bestfaceswap\.ai\/?/,
         ];
    Sentry.init({
      app: [vueApp],
      dsn: config.public.dsn,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: tracePropagationTargets,
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
});