import axios from 'axios';
import appConfig from '~/app.config';

// const baseUrl = process.env.NODE_ENV == 'development' ? 'https://apitest.bestfaceswap.ai' : 'https://api.bestfaceswap.ai'
const baseUrl = appConfig.apiBase
const request = axios.create({
    baseURL: baseUrl
});

request.interceptors.request.use(function (request) {
    const {token} = useAuthStore()
    if (token) {
        request.headers.Authorization = `Bearer ${token}`
    }
    return request;
}, function (error) {
    return Promise.reject(error);
});
request.interceptors.response.use(function (response) {
    // 统一处理响应数据
    if (response.data && response.data.code === 200) {
        return response.data.data; // 假设业务数据在 data.data 中
    } else {
        // 处理其他非 200 状态码
        return Promise.reject(response.data.message);
    }
}, async function (error) {
    const auth = useAuthStore()
    const snackbarStore = useSnackbarStore();
    if (error.response?.status === 401 || error.response?.status === 403) {
        snackbarStore.showError(error.response?.data?.message || error.response || error);
        await auth.logout('/login/google')
    } else {
        // snackbarStore.showError(error.response?.data?.message || 'Unknown error');
        console.log(error);
    }
    return Promise.reject(error);
});

export default request;
