<script lang="ts" setup>
const router = useRouter()
const { logout } = useAuthStore()
const { setUserInfo, setProducts, userInfo } = useCommStore()

const localePath = useLocalePath()
function handleLogout() {
    logout(localePath('/'))
}

const userStatusClass = computed(() => {
    switch (userInfo.user_status) {
        case 'Free':
            return 'bg-green-500';
        case 'Premium':
            return 'bg-orange-500';
        case 'Expired':
            return 'bg-red-500';
        default:
            return 'bg-gray-500';
    }
});
</script>
<template>
    <v-row class="mt-4">
        <div id="account-info" class="w-full bg-[#212329] px-9 py-6 min-w-[368px] rounded-xl font-bold"
            style="box-shadow: 0px 2px 5px 0px #00000040;">
            <div class="title font-extrabold text-[20px]">Account Info</div>
            <div class="content pb-4">
                <v-row @click="router.push(localePath('/my/account'))" class="bg-[#2A2C32] rounded-lg p-2 bdo">
                    <v-col class="label flex items-center" align="start">
                        <v-icon icon="mdi mdi-account-circle mr-1"></v-icon>
                        <span>Account</span>
                    </v-col>
                    <v-col align="end">
                        <span class="rounded-full text-black font-semibold  text-sm px-2 py-1" :class="userStatusClass">
                            <v-icon v-if="userInfo.user_status === 'Premium'" icon="mdi-crown"
                                class="text-sm relative top-[-2px]"></v-icon>
                            {{ userInfo.user_status }}
                        </span>
                        <v-icon icon="mdi-chevron-right"></v-icon>
                    </v-col>
                </v-row>
                <v-row @click="router.push(localePath('/my/credits'))" class="bg-[#2A2C32] rounded-lg p-2 bdo">
                    <v-col class="label flex items-center" align="start">
                        <v-icon icon="mdi-star-face mr-1"></v-icon>
                        <span>Credits</span>
                    </v-col>
                    <v-col align="end">
                        <span class="text-[#FF840D]">{{ userInfo.credit_num || 0 }}</span>
                        <v-icon icon="mdi-chevron-right"></v-icon>
                    </v-col>
                </v-row>
                <v-row @click="router.push(localePath('/my/faces'))" class="bg-[#2A2C32] rounded-lg p-2 bdo">
                    <v-col class="label flex items-center" align="start">
                        <v-icon icon="mdi-face-man mr-1"></v-icon>
                        <span>My Faces</span>
                    </v-col>
                    <v-col align="end">
                        <span>{{ userInfo.counter_faces || 0 }}</span>
                        <v-icon icon="mdi-chevron-right"></v-icon>
                    </v-col>
                </v-row>
                <v-row class="bg-[#2A2C32] rounded-lg p-2 bdo" @click="handleLogout">
                    <v-col class="label flex items-center" align="start">
                        <v-icon icon="mdi-logout" class="mr-1"></v-icon>
                        <span>Log Out</span>
                    </v-col>
                    <v-col align="end">
                        <!-- <span>2024-06-30</span> -->
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-row>
</template>
<style>
.bdo {
    cursor: pointer;
    border: 1px solid #2A2C32;
    margin-top: 25px !important;
}

.bdo:hover {
    border: 1px solid #FF840D !important;
}
</style>