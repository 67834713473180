import { default as creation_detailkdXvPK9DSNMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/creation_detail.vue?macro=true";
import { default as face_45swap_45photoeObDihsXNPMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/face-swap-photo.vue?macro=true";
import { default as face_45swap_45videoQmD3JRRXS1Meta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/face-swap-video.vue?macro=true";
import { default as face_45swapnkazIQdWEBMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/face-swap.vue?macro=true";
import { default as index_45oldgm6x0AfkXzMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/index-old.vue?macro=true";
import { default as indexZlYkGG8ZyXMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/index.vue?macro=true";
import { default as _91_91site_93_93uypfWIAo4rMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/login/[[site]].vue?macro=true";
import { default as accountALP37ZQseeMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/my/account.vue?macro=true";
import { default as creationsj5IS5ChT0NMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/my/creations.vue?macro=true";
import { default as credits1ctr0PTbDGMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/my/credits.vue?macro=true";
import { default as facessmNNTVcv0sMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/my/faces.vue?macro=true";
import { default as uploadsORMO6dolwIMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/my/uploads.vue?macro=true";
import { default as myKcFVTI1xGZMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/my.vue?macro=true";
import { default as payment_45failedU5zw3kO5BcMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/payment-failed.vue?macro=true";
import { default as payment_45successxeWhPrgwqUMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/payment-success.vue?macro=true";
import { default as policywf9i9JRpHZMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/policy.vue?macro=true";
import { default as pricing_45oldiZ2UiYRac6Meta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/pricing-old.vue?macro=true";
import { default as pricingjYTYK3IGYjMeta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/pricing.vue?macro=true";
import { default as termsqxbgFeGkG6Meta } from "/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/terms.vue?macro=true";
export default [
  {
    name: creation_detailkdXvPK9DSNMeta?.name ?? "creation_detail___en",
    path: creation_detailkdXvPK9DSNMeta?.path ?? "/creation_detail",
    meta: creation_detailkdXvPK9DSNMeta || {},
    alias: creation_detailkdXvPK9DSNMeta?.alias || [],
    redirect: creation_detailkdXvPK9DSNMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/creation_detail.vue").then(m => m.default || m)
  },
  {
    name: face_45swap_45photoeObDihsXNPMeta?.name ?? "face-swap-photo___en",
    path: face_45swap_45photoeObDihsXNPMeta?.path ?? "/face-swap-photo",
    meta: face_45swap_45photoeObDihsXNPMeta || {},
    alias: face_45swap_45photoeObDihsXNPMeta?.alias || [],
    redirect: face_45swap_45photoeObDihsXNPMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/face-swap-photo.vue").then(m => m.default || m)
  },
  {
    name: face_45swap_45videoQmD3JRRXS1Meta?.name ?? "face-swap-video___en",
    path: face_45swap_45videoQmD3JRRXS1Meta?.path ?? "/face-swap-video",
    meta: face_45swap_45videoQmD3JRRXS1Meta || {},
    alias: face_45swap_45videoQmD3JRRXS1Meta?.alias || [],
    redirect: face_45swap_45videoQmD3JRRXS1Meta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/face-swap-video.vue").then(m => m.default || m)
  },
  {
    name: face_45swapnkazIQdWEBMeta?.name ?? "face-swap___en",
    path: face_45swapnkazIQdWEBMeta?.path ?? "/face-swap",
    meta: face_45swapnkazIQdWEBMeta || {},
    alias: face_45swapnkazIQdWEBMeta?.alias || [],
    redirect: face_45swapnkazIQdWEBMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/face-swap.vue").then(m => m.default || m)
  },
  {
    name: index_45oldgm6x0AfkXzMeta?.name ?? "index-old___en",
    path: index_45oldgm6x0AfkXzMeta?.path ?? "/index-old",
    meta: index_45oldgm6x0AfkXzMeta || {},
    alias: index_45oldgm6x0AfkXzMeta?.alias || [],
    redirect: index_45oldgm6x0AfkXzMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/index-old.vue").then(m => m.default || m)
  },
  {
    name: indexZlYkGG8ZyXMeta?.name ?? "index___en",
    path: indexZlYkGG8ZyXMeta?.path ?? "/",
    meta: indexZlYkGG8ZyXMeta || {},
    alias: indexZlYkGG8ZyXMeta?.alias || [],
    redirect: indexZlYkGG8ZyXMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91site_93_93uypfWIAo4rMeta?.name ?? "login-site___en",
    path: _91_91site_93_93uypfWIAo4rMeta?.path ?? "/login/:site?",
    meta: _91_91site_93_93uypfWIAo4rMeta || {},
    alias: _91_91site_93_93uypfWIAo4rMeta?.alias || [],
    redirect: _91_91site_93_93uypfWIAo4rMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/login/[[site]].vue").then(m => m.default || m)
  },
  {
    name: myKcFVTI1xGZMeta?.name ?? "my___en",
    path: myKcFVTI1xGZMeta?.path ?? "/my",
    meta: myKcFVTI1xGZMeta || {},
    alias: myKcFVTI1xGZMeta?.alias || [],
    redirect: myKcFVTI1xGZMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/my.vue").then(m => m.default || m),
    children: [
  {
    name: accountALP37ZQseeMeta?.name ?? "my-account___en",
    path: accountALP37ZQseeMeta?.path ?? "account",
    meta: accountALP37ZQseeMeta || {},
    alias: accountALP37ZQseeMeta?.alias || [],
    redirect: accountALP37ZQseeMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/my/account.vue").then(m => m.default || m)
  },
  {
    name: creationsj5IS5ChT0NMeta?.name ?? "my-creations___en",
    path: creationsj5IS5ChT0NMeta?.path ?? "creations",
    meta: creationsj5IS5ChT0NMeta || {},
    alias: creationsj5IS5ChT0NMeta?.alias || [],
    redirect: creationsj5IS5ChT0NMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/my/creations.vue").then(m => m.default || m)
  },
  {
    name: credits1ctr0PTbDGMeta?.name ?? "my-credits___en",
    path: credits1ctr0PTbDGMeta?.path ?? "credits",
    meta: credits1ctr0PTbDGMeta || {},
    alias: credits1ctr0PTbDGMeta?.alias || [],
    redirect: credits1ctr0PTbDGMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/my/credits.vue").then(m => m.default || m)
  },
  {
    name: facessmNNTVcv0sMeta?.name ?? "my-faces___en",
    path: facessmNNTVcv0sMeta?.path ?? "faces",
    meta: facessmNNTVcv0sMeta || {},
    alias: facessmNNTVcv0sMeta?.alias || [],
    redirect: facessmNNTVcv0sMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/my/faces.vue").then(m => m.default || m)
  },
  {
    name: uploadsORMO6dolwIMeta?.name ?? "my-uploads___en",
    path: uploadsORMO6dolwIMeta?.path ?? "uploads",
    meta: uploadsORMO6dolwIMeta || {},
    alias: uploadsORMO6dolwIMeta?.alias || [],
    redirect: uploadsORMO6dolwIMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/my/uploads.vue").then(m => m.default || m)
  }
]
  },
  {
    name: payment_45failedU5zw3kO5BcMeta?.name ?? "payment-failed___en",
    path: payment_45failedU5zw3kO5BcMeta?.path ?? "/payment-failed",
    meta: payment_45failedU5zw3kO5BcMeta || {},
    alias: payment_45failedU5zw3kO5BcMeta?.alias || [],
    redirect: payment_45failedU5zw3kO5BcMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/payment-failed.vue").then(m => m.default || m)
  },
  {
    name: payment_45successxeWhPrgwqUMeta?.name ?? "payment-success___en",
    path: payment_45successxeWhPrgwqUMeta?.path ?? "/payment-success",
    meta: payment_45successxeWhPrgwqUMeta || {},
    alias: payment_45successxeWhPrgwqUMeta?.alias || [],
    redirect: payment_45successxeWhPrgwqUMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/payment-success.vue").then(m => m.default || m)
  },
  {
    name: policywf9i9JRpHZMeta?.name ?? "policy___en",
    path: policywf9i9JRpHZMeta?.path ?? "/policy",
    meta: policywf9i9JRpHZMeta || {},
    alias: policywf9i9JRpHZMeta?.alias || [],
    redirect: policywf9i9JRpHZMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: pricing_45oldiZ2UiYRac6Meta?.name ?? "pricing-old___en",
    path: pricing_45oldiZ2UiYRac6Meta?.path ?? "/pricing-old",
    meta: pricing_45oldiZ2UiYRac6Meta || {},
    alias: pricing_45oldiZ2UiYRac6Meta?.alias || [],
    redirect: pricing_45oldiZ2UiYRac6Meta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/pricing-old.vue").then(m => m.default || m)
  },
  {
    name: pricingjYTYK3IGYjMeta?.name ?? "pricing___en",
    path: pricingjYTYK3IGYjMeta?.path ?? "/pricing",
    meta: pricingjYTYK3IGYjMeta || {},
    alias: pricingjYTYK3IGYjMeta?.alias || [],
    redirect: pricingjYTYK3IGYjMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: termsqxbgFeGkG6Meta?.name ?? "terms___en",
    path: termsqxbgFeGkG6Meta?.path ?? "/terms",
    meta: termsqxbgFeGkG6Meta || {},
    alias: termsqxbgFeGkG6Meta?.alias || [],
    redirect: termsqxbgFeGkG6Meta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/web-aivideo-faceswap/pages/terms.vue").then(m => m.default || m)
  }
]