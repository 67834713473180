// stores/useUtmStore.ts
import { defineStore } from 'pinia'
import { ref, watch, onMounted } from 'vue'

export const useUtmStore = defineStore('utmStore', () => {
  const utmParams = ref({
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    click_id: ''
  })

  function loadUtmParamsFromLocalStorage() {
    if (typeof localStorage !== 'undefined') {
      const storedParams = localStorage.getItem('utmParams')
      if (storedParams) {
        utmParams.value = JSON.parse(storedParams)
      }
    }
  }

  function saveUtmParamsToLocalStorage() {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('utmParams', JSON.stringify(utmParams.value))
    }
  }

  function setUtmParams(params: Record<string, string | undefined>) {
    utmParams.value.utm_source = params.utm_source || params.via || ''
    utmParams.value.utm_medium = params.utm_medium || ''
    utmParams.value.utm_campaign = params.utm_campaign || ''
    utmParams.value.utm_content = params.utm_content || ''
    utmParams.value.click_id = params.click_id || ''
    saveUtmParamsToLocalStorage()
  }

  // 在客户端环境中初始化时从 localStorage 加载 UTM 参数
  if (typeof window !== 'undefined') {
    onMounted(() => {
      loadUtmParamsFromLocalStorage()
    })
  }

  // 在参数变化时保存到 localStorage
  watch(utmParams, saveUtmParamsToLocalStorage, { deep: true })

  return {
    utmParams,
    setUtmParams
  }
}, {
  persist: true // 持久化选项
})
