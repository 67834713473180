  // plugins/clarity.client.ts
export default defineNuxtPlugin(() => {
    if (process.client) {
      const loadClarity = () => {
        (function(c, l, a, r, i, t, y) {
          c[a] = c[a] || function() { (c[a].q = c[a].q || []).push(arguments) };
          t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
          y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "n7j8ylk9r1");
      };
  
      if ('requestIdleCallback' in window) {
        requestIdleCallback(loadClarity);
      } else {
        setTimeout(loadClarity, 0);
      }
    }
  });
  