// useAuthStore.ts
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

export const useAuthStore = defineStore('authStore', () => {
    const token = ref('');
    const isLogin = ref(false);

    const comm = useCommStore();
    
    watch(token, () => {
        isLogin.value = !!token.value;
    });

    function login(tk) {
        console.log('tk', tk);
        token.value = tk;
        // 设置用户信息
        comm.setUserInfo();
    }

    function logout(redirectTo = '/') {
        const router = useRouter()
        token.value = '';
        comm.clearUserInfo();
        router.push(redirectTo)
    }

    return {
        login,
        logout,
        token,
        isLogin
    };
}, {
    persist: true // 直接设置字符串可直接变更 cookie的值
});
